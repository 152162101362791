import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Toby Prafke'}</title>
        <html lang={lang || 'en'} />
        <meta
          name="description"
          content={
            description ||
            'In order to learn more, I attended local coding meetups and found my passion for JavaScript development. This experience drove me to enroll in a fulltime coding boot camp where I utilized Agile methodologies to collaboratively build websites. My mission is to drive results through enterprise software development and public interest technology.'
          }
        />
        <meta
          name="image"
          property="og:image"
          content="https://avatars.githubusercontent.com/u/64608862?v=4"
        />
      </Helmet>
      <App />
    </>
  );
};
